<template>
  <v-card
    id="define-map"
    flat
    min-height="550"
    min-width="550"
    max-height="900"
    max-width="1200"
  >
    <v-card
      elevation="8"
      min-height="500"
      min-width="500"
      max-height="800"
      max-width="1200"
    >
      <div
        id="map"
        class="map"
      />
    </v-card>

    <v-card-actions>
      <v-btn
        dark
        color="deep-orange accent-3"
        @click="clearMap"
      >
        Clear
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        @click="getFullDraw"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import WKT from 'ol/format/WKT';
import GeoJSON from 'ol/format/GeoJSON';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      fullDraw: '',
      raster: undefined,
      source: undefined,
      vector: undefined,
      map: undefined,
      WKTObject: new WKT(),
      GeoJSON: new GeoJSON(),
      alert: false
    };
  },
  computed: {
    ...mapState(['domain'])
  },
  async mounted() {
    await this.initiateMap();
  },
  methods: {
    ...mapActions(['storeDomainPolygon']),
    initiateMap() {
      this.raster = new TileLayer({
        source: new OSM()
      });

      this.source = new VectorSource({ wrapX: false });

      this.vector = new VectorLayer({
        source: this.source
      });

      this.map = new Map({
        layers: [this.raster, this.vector],
        target: 'map',
        view: new View({
          projection: 'EPSG:4326',
          center: [12.002134, 42.423457],
          zoom: 5.7
        })
      });

      try {
        this.source.addFeatures(this.GeoJSON.readFeatures(this.domain.geoJSON));
      } catch {
        this.$emit('errorMap');
      }
    },

    async getFullDraw() {
      const features = this.source.getFeatures();

      for (let i = 0;i < features.length;i++) {
        if (i === 0) {
          if (features[i].getGeometry().getType() == 'MultiPolygon') {
            this.fullDraw = features[i].getGeometry();
          } else {
            this.fullDraw = new MultiPolygon(features[i].getGeometry());
            this.fullDraw.appendPolygon(features[i].getGeometry());
          }
        } else {
          this.fullDraw.appendPolygon(features[i].getGeometry());
        }
      }

      if (features.length > 0) {
        this.storeDomainPolygon(this.WKTObject.writeGeometry(this.fullDraw));
      }

      this.emitConfirm();
    },

    clearMap() {
      this.source.clear();
      this.fullDraw = '';
      this.emitEvent();
    },

    emitEvent() {
      this.$emit('closeMap');
    },

    emitConfirm() {
      this.$emit('confirmPolygon');
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  position: absolute;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
}
</style>
